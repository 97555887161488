import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/global/Seo';
import { Heading, Button, Subtitle } from '../components/elements';

const Container = styled.section`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url('/images/404.png');
  .subtitle {
    line-height: 30px;
  }
`;

const NotFoundPage = () => (
  <Layout>
    <Seo title="NOT FOUND" />
    <Container className="section is-medium">
      <div className="container">
        <div className="columns">
          <div className="column is-8">
            <Heading className="mb-4">OOPS, YOU MISSED THE GOAL.</Heading>
            <Subtitle className="subtitle is-4">
              The page you were looking for seems to have been sent off, please
              try another page.
            </Subtitle>
            <Link to="/">
              <Button>HomePage</Button>
            </Link>
          </div>
        </div>
      </div>
    </Container>
  </Layout>
);

export default NotFoundPage;
